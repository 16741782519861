import React from 'react'
import Helmet from 'react-helmet'

import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../../components/Layout'
import FullPage from '../../components/FullPage'
import WorkTitleBlock from '../../components/WorkTitleBlock';

export const frontmatter = {
  title: 'Bifit Logo',
  type: 'Identity',
  client: 'Private',
  path: '/works/identity/bifit-logo/',
  date: '2011-01-14',
  image: 'bifit-logo-demo',
}

export default ({ location, data }) => (
  <Layout location={location}>
    <Helmet title={frontmatter.title} />
    <FullPage>
      <figure className="text-center">
        <Image
          fluid={data.file.childImageSharp.fluid}
          style={{ margin: 'auto', maxWidth: 600 }}
        />
      </figure>
    </FullPage>

    <WorkTitleBlock {...frontmatter} />
  </Layout>
)

export const query = graphql`
  query GatsbyImageSampleQuery {
    file(relativePath: { eq: "bifit/i/bifit-logo-full.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
